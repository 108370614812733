








import { Component, Vue } from 'vue-property-decorator';
import Page from '@/components/Page.vue';
import Card from '@/components/card/Card.vue';
import { Id } from '../../user/domain/Types';
import { Creative } from '../domain/Creative';
import { CreativeService } from '../services/CreativeService';
import CreativeForm from '../components/CreativeForm.vue';
import { CreativeState } from '@/modules/creative/domain/CreativeState';

@Component({
    components: { Page, Card, CreativeForm },
})
export default class ViewCreative extends Vue {
    private isLoading: boolean = false;
    private creativeId: Id = '';
    private creative: Creative | Creative = {
        id: '',
        name: '',
        startDate: '',
        endDate: '',
        state: CreativeState.INACTIVE,
        categoryId: undefined,
        campaignId: undefined,
        media: '',
        type: '',
        duration: 15,
        zones: [],
        portalId: undefined,
        title: '',
        description: '',
        url: '',
    };

    private async mounted() {
        if (this.$route.params.creativeId) {
            this.creativeId = this.$route.params.creativeId;
        }

        await this.fetchCreative();
    }

    private async fetchCreative() {
        this.isLoading = true;
        try {
            this.creative = await CreativeService.getBy(
                this.creativeId,
                this.$store.getters['sessionStore/token'],
            );
        } catch {
            this.$notify.error({
                title: 'Erreur',
                message: 'Impossible de trouver le créatif.',
            });
            return this.$router.push('/view-campaign');
        }
        this.isLoading = false;
    }

    private async onCreativeSave(creative: Creative) {
        this.isLoading = true;
        try {
            await CreativeService.update(
                creative,
                this.$store.getters['sessionStore/token'],
            );
            // @ts-ignore
            return this.$router.push(`/view-campaign/${creative.campaignId}`);
        } catch (e) {
            this.$notify.error({
                title: 'Erreur',
                message: 'Impossible de trouver la campagne.',
            });
            return this.$router.push('/view-campaign');
        }
        this.isLoading = false;
    }
}
